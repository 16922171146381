import winAwsVpnClientLoginSuccess from "./aws-vpn-client-login-successful.png";
import idPrismEnterEmailAddrPng from "./idprism-enter-email-address.png";
import idPrismEnterPwPng from "./idprism-enter-password.png";
import idPrismVerificationCodePng from "./idprism-verification-code.png";
import ovpnDlPagePng from "./ovpn-download-page.png";
import scTestSuccess from "./siteconnect-successful-test.png";
import scTestFail from "./siteconnect-unsuccessful-test.png";
import userAccessTablePng from "./user-access-table.png";
import controlPanelPng from "./windows/control-panel.png";
import dvcMgrPng from "./windows/device-manager.png";
import installVpnClientFinish from "./windows/install-aws-vpn-client-finish.png";
import installVpnClientLicenseAgreement from "./windows/install-aws-vpn-client-license-agreement.png";
import installVpnClient from "./windows/install-aws-vpn-client.png";
import installSCPostureClientDestDir from "./windows/install-siteconnect-web-server-destination-directory.png";
import installSCPostureClientFinish from "./windows/install-siteconnect-web-server-finish.png";
import installSCPostureClient from "./windows/install-siteconnect-web-server.png";
import installTapAdapterPng from "./windows/install-tap-adapter.png";
import runPSAdmin from "./windows/run-powershell-as-administrator.png";
import unknownErrorPng from "./windows/unknown-error.png";
import winAwsVpnClientAddProfilePng from "./windows/windows-aws-vpn-client-add-profile.png";
import winAwsVpnClientCreateProfilePng from "./windows/windows-aws-vpn-client-create-profile.png";
import winAwsVpnClientOpenPng from "./windows/windows-aws-vpn-client-open.png";
import winAwsVpnClientProfileAddedPng from "./windows/windows-aws-vpn-client-profile-added.png";
import winAwsVpnClientProfileDetailsPng from "./windows/windows-aws-vpn-client-profile-details.png";
import winAwsVpnClientProfileSelectedPng from "./windows/windows-aws-vpn-client-profile-selected.png";

export class Images {
  static readonly scTestSuccess = scTestSuccess;
  static readonly scTestFail = scTestFail;
  static readonly installVpnClientFinish = installVpnClientFinish;
  static readonly installVpnClientLicenseAgreement = installVpnClientLicenseAgreement;
  static readonly installVpnClient = installVpnClient;
  static readonly installSCPostureClientDestDir = installSCPostureClientDestDir;
  static readonly installSCPostureClientFinish = installSCPostureClientFinish;
  static readonly installSCPostureClient = installSCPostureClient;
  static readonly runPSAdmin = runPSAdmin;
  static readonly winAwsVpnClientLoginSuccess = winAwsVpnClientLoginSuccess;
  static readonly idPrismEnterEmailAddrPng = idPrismEnterEmailAddrPng;
  static readonly idPrismEnterPwPng = idPrismEnterPwPng;
  static readonly idPrismVerificationCodePng = idPrismVerificationCodePng;
  static readonly ovpnDlPagePng = ovpnDlPagePng;
  static readonly userAccessTablePng = userAccessTablePng;
  static readonly winAwsVpnClientAddProfilePng = winAwsVpnClientAddProfilePng;
  static readonly winAwsVpnClientCreateProfilePng = winAwsVpnClientCreateProfilePng;
  static readonly winAwsVpnClientOpenPng = winAwsVpnClientOpenPng;
  static readonly winAwsVpnClientProfileAddedPng = winAwsVpnClientProfileAddedPng;
  static readonly winAwsVpnClientProfileDetailsPng = winAwsVpnClientProfileDetailsPng;
  static readonly winAwsVpnClientProfileSelectedPng = winAwsVpnClientProfileSelectedPng;
  static readonly controlPanelPng = controlPanelPng;
  static readonly dvcMgrPng = dvcMgrPng;
  static readonly installTapAdapterPng = installTapAdapterPng;
  static readonly unknownErrorPng = unknownErrorPng;
}
