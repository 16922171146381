import { Container, Link } from "@amzn/awsui-components-react";
import { BasePageProps } from "../../../common/pageProps";

export const FAQPage = ({ setPageTitle }: BasePageProps) => {
  setPageTitle("SiteConnect FAQs");
  return (
    <Container fitHeight>
      <h2>Why are internet resources blocked when I use SiteConnect?</h2>
      <p>
        For security reasons, SiteConnect is configured to only allow access to
        the specific site resources that have been permitted for you. It is
        deliberately designed to block all other internet access and prevent new
        internet connections. This is to ensure you can only access the
        resources on the private network you have been granted access to through
        SiteConnect. When connected to SiteConnect, you will have limited
        internet access on ports 80 and 443, as well as port 3478 for Chime
        access.
      </p>

      <h2>Where can I download the client software?</h2>
      <p>
        You can find the links to download the required AWS Client VPN software
        on the{" "}
        <Link href="/windows/install.html">SiteConnect installation page</Link>{" "}
        or the{" "}
        <Link
          external
          href="https://aws.amazon.com/vpn/client-vpn-download/"
          target="_blank"
        >
          AWS Client VPN download page
        </Link>
        .
      </p>

      <h2>Why must I create a profile for each site on my client device?</h2>
      <p>
        Each site you need to access is connected to a separate VPN that is set
        up for a specific group of users. The VPN location information is
        contained in the configuration (OVPN) file, and this file is required to
        connect to the correct VPN for the site you are accessing.
      </p>

      <h2>Do I need to download a new OVPN file each time I access a site?</h2>
      <p>
        No, you do not need to download a new profile each time. You can use a
        previously set up profile and simply connect again, as long as your
        Amazon POC has granted you access. However, you will still be required
        to authenticate with IdPrism and you still have to request access from
        your Amazon POC first. If you experience any problems accessing the VPN
        or with your devices, you may be asked to delete the old profile and
        install a brand new one as part of the troubleshooting process.
      </p>

      <h2>How long is my access good for?</h2>
      <p>
        Once your Amazon POC has granted you access, your initial access is
        valid for 30 minutes. If you successfully connect within that 30-minute
        window, your access will be extended to 8 hours. During this 8-hour
        period, you may disconnect and reconnect as many times as needed without
        requesting new access to that specific network.
      </p>

      <h2>How do I escalate when there are issues?</h2>
      <p>
        If you encounter any issues, your Amazon POC will be able to contact the
        correct team to assist with troubleshooting. Please be prepared to
        provide any relevant information, such as screenshots, error messages,
        log files, and the steps you have taken to reproduce the issue.
      </p>

      <h2>Should I update my AWS VPN Client?</h2>
      <p>
        Yes, if there is an update available for the AWS VPN Client, you should
        always upgrade to the latest version, unless you are specifically
        instructed otherwise.
      </p>

      <h2> I cannot login to IdPrism. What do I do?</h2>
      <p>
        If you know you have an account already, you can go to the{" "}
        <Link
          external
          href="https://opstechitgate.cupid-na.idprism-auth.amazon.dev/"
          target="_blank"
        >
          IdPrism login page
        </Link>
        , input your email, and click Forgot Password. This will trigger a
        password reset email. If you are unsure if you have account or need
        assistance with the password reset, your Amazon POC can take of this for
        you.
      </p>

      <h2>
        {" "}
        I am not receving the email with the access information when granted
        access.
      </h2>
      <p>
        Every time you are granted access, an email is sent with the specific
        access information. If you are not receiving these emails you need to
        work with your IT department or email administrators to ensure the
        emails are not being quarantined. The sender will be{" "}
        <b>grantorservice-noreply@siteconnect.opstech.a2z.com</b> and the
        following URLs should be allowed:
        <ul>
          <li>instructions.siteconnect.opstech.a2z.com</li>
          <li>self-service.clientvpn.amazonaws.com</li>
        </ul>
        If after all this you are still not receiving emails, reach out to your
        Amazon POC.
      </p>
    </Container>
  );
};
