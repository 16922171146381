import { Box, Cards, Container, Header, Link } from '@amzn/awsui-components-react';
import { BasePageProps } from '../../common/pageProps';
import { MonitorSVG } from "../../icons/monitor";

export const HomePage = ({setPageTitle}: BasePageProps) => {
  setPageTitle('Amazon SiteConnect VPN');

  return (
    <Container
      fitHeight
      header={
        <Header
          variant='h1'
          description={
            <p>
              SiteConnect is a VPN remote access solution to enable vendors to configure and
              monitor onsite equipment at externalized sites remotely.<br /><br />
              If you have any questions about the Siteconnect process <b>reach out to your
                Amazon point of contact.</b>
            </p>
            }
        />
      }
    >
      <Header variant='h2' description='Follow the platform specific instructions'>Install</Header>
      <br />
      <Cards
        variant='container'
        cardDefinition={{
          header: item => (
            <Link href={item.href}>
              <Box textAlign='center' variant='small' padding={'m'}>
                <MonitorSVG />
                <h3>{item.title}</h3>
              </Box>
            </Link>
          )
        }}
        items={[
          { href: "/windows/install.html", title: "Windows" },
        ]}
      />
    </Container>
  );
};
