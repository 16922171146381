import { Alert, Container, Header, Link } from "@amzn/awsui-components-react";
import { Errors, PosturePayload } from '@amzn/siteconnect-idp-posture-assessment/dist/lib/models';
import { returnPosturePayload } from '@amzn/siteconnect-idp-posture-assessment/dist/lib/network';
import { useEffect, useState } from 'react';
import { BasePageProps } from '../../../common/pageProps';
import { CodeBlockWithCopy } from '../../../common/utils';

const updateCodeText =
  'Set-ExecutionPolicy -ExecutionPolicy Unrestricted -Scope Process -Force\n& "C:\\Program Files (x86)\\Amazon\\SiteConnect\\configure-siteconnect.ps1" update';

const containerDescription = (<Alert type='warning'>
  Prior to Posture Assessment Client version 0.1.5, the script below could fail to successfully update the software.<br />
  In this scenario, follow the instructions on the <Link
    href='/windows/install.html#install-siteconnect-posture-client'
  >Install page</Link> to download and install the latest version of the SiteConnect Posture Assessment Client.
</Alert>)

export const UpdatingPage = ({ setPageTitle }: BasePageProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [installedVersion, setInstalledVersion] = useState<string>('Unknown');
  const [latestVersion, setLatestVersion] = useState<string>('Unknown');

  useEffect(() => {
    const getPayload = async () => {
      const clientPosturePayload: PosturePayload | Errors = await returnPosturePayload();
      if ('Errors' in clientPosturePayload) {
        console.error(clientPosturePayload.Errors);
      } else {
        if (clientPosturePayload.webServerVersion) {
          setInstalledVersion(Object.values(clientPosturePayload.webServerVersion).join('.'));
        }
        if (clientPosturePayload.latestWebServerVersion) {
          setLatestVersion(Object.values(clientPosturePayload.latestWebServerVersion).join('.'));
        }
      }

      setLoading(false);
    }

    loading && getPayload();
  }, [loading, installedVersion]);

  setPageTitle('Updating The SiteConnect Posture Assessment Client');
  return (
    <Container
      fitHeight
      header={<Header variant='h1' description={containerDescription} />
    }>
      <Header variant='h2'>Your Posture Assessment Client Version: {installedVersion}</Header>
      <Header variant='h3'>Latest Version: {latestVersion}</Header>
      <p>
        You may be notified that you must update the SiteConnect software to continue using the VPN.
      </p>
      <p>
        To update, simply run the following command in PowerShell as an Administrator:
      </p>
      <CodeBlockWithCopy code={updateCodeText} />
    </Container>
  );
}
