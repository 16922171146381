import { CodeView } from '@amzn/awsui-code-view';
import { CopyToClipboard, FlashbarProps } from "@amzn/awsui-components-react";
import axios, { AxiosRequestConfig } from "axios";
import MockAdapter from "axios-mock-adapter";
import { useState } from "react";
import { ACCOUNT_MAPPINGS } from "./constants";

export const axiosInstance = axios.create();

export const getPostureResult = async (
  postureData: string,
  userId: string | undefined
) => {
  const reqConfig: AxiosRequestConfig = {
    params: {
      user_posture_info: postureData,
      user_id: userId ?? "posturetest-user",
    },
  };

  const hostname = window?.location.hostname;
  const apiDomain = ACCOUNT_MAPPINGS.get(hostname);

  return await axiosInstance.get(`https://${apiDomain}`, reqConfig);
};

export const mockApi = () => {
  const mockAdapter = new MockAdapter(axiosInstance, { delayResponse: 100 });

  // if you want to edit the posture response do it here manually - this
  // is for quick feedback in the mock server
  mockAdapter.onGet().reply(() => [200, { result: true, reason: {} }]);
  return mockAdapter;
};

export const useFlashbarMessages = (): [
  FlashbarProps.MessageDefinition[],
  (content: string, type: FlashbarProps.Type) => void
] => {
  const [flashbarMessages, setFlashbarMessages] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  const createFlashbarMessage = (content: string, type: FlashbarProps.Type) => {
    const now = Date.now();
    setFlashbarMessages(
      flashbarMessages.concat([
        {
          type: type,
          dismissible: true,
          dismissLabel: "Dismiss message",
          onDismiss: () =>
            setFlashbarMessages((prevState) =>
              prevState.filter((message) => message.id !== now.toString())
            ),
          content: content,
          id: now.toString(),
        },
      ])
    );
  };

  return [flashbarMessages, createFlashbarMessage];
};

export const CodeBlockWithCopy = ({ code }:{code: string}) => (
  <CodeView
    content={code}
    actions={
      <CopyToClipboard
        popoverRenderWithPortal
        variant='button'
        copyButtonText='Copy Code'
        copySuccessText='Code copied'
        copyErrorText='Failed to copy'
        textToCopy={code}
      />
    }
  />
)
